<template>
  <div class="adminmenu">
    <div class="users_form">
      <el-form :inline="true" :model="searchForm" size="small">
        <el-form-item label="真實姓名：">
          <el-input
            v-model="searchForm.realname"
            placeholder="請輸入真實姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手機號：">
          <el-input
            v-model="searchForm.phone"
            placeholder="請輸入手機號"
          ></el-input>
        </el-form-item>
        <el-form-item label="創建時間：">
          <el-date-picker
            v-model="searchDate"
            type="daterange"
            range-separator="~"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用戶名：">
          <el-input
            v-model="searchForm.admin_name"
            placeholder="請輸入用戶名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="addmenu">添加</el-button>
          <el-button type="primary" @click="getUserlist">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="tableLoading"
      :data="userTableData"
      :border="true"
      class="table"
    >
      <el-table-column prop="realname" label="真實姓名"></el-table-column>
      <el-table-column prop="admin_name" label="用戶名"> </el-table-column>
      <el-table-column
        prop="phone"
        label="手機號"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column prop="create_time" width="160" label="創建時間">
      </el-table-column>
      <el-table-column prop="remark" label="備註" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="editUser(scope.row.admin_id)"
            >編輯</el-button
          >
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="deleteUser(scope.row.admin_id)"
            >刪除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 添加 -->
    <el-dialog
      :title="userTitle + '用戶'"
      :visible.sync="userVisible"
      width="600px"
    >
      <el-form
        :model="userForm"
        :rules="userFormRules"
        ref="userForm"
        label-width="100px"
        class="demo-ruleForm"
        size="small"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="用戶名" prop="admin_name">
              <el-input v-model="userForm.admin_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="真實姓名" prop="realname">
              <el-input v-model="userForm.realname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手機號" prop="phone">
              <el-input v-model="userForm.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="密碼"
              v-if="userTitle == '添加'"
              prop="password"
            >
              <el-input
                v-model="userForm.password"
                placeholder="請輸入6位數密碼"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="密碼"
              v-if="userTitle == '編輯'"
              prop="password"
            >
              <el-input
                v-model="userForm.password"
                placeholder="為空則表示不修改密碼"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="選擇角色">
              <el-select
                v-model="userForm.role_ids"
                multiple
                placeholder="請選擇"
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.role_id"
                  :label="item.role_name"
                  :value="item.role_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="狀態" prop="status">
              <el-radio-group v-model="userForm.status">
                <el-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="備註" prop="remark">
              <el-input type="textarea" v-model="userForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="userSubmit"
          >提交</el-button
        >
        <el-button size="small" @click="userVisible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AddminIndexMerchantsmenu",
  data() {
    return {
      page: 1,
      pageSize: 50,
      total: 0,
      searchForm: {
        realname: "",
        create_time_from: "",
        create_time_to: "",
        phone: "",
        admin_name: "",
      },
      searchDate: [],
      tableLoading: false,
      userTableData: [],
      userTitle: "添加",
      userForm: {},
      statusList: [
        { label: "正常", value: 1 },
        { label: "禁用", value: 2 },
      ],
      userOptions: [],
      userVisible: false,
      userFormRules: {},
    };
  },
  mounted() {
    this.getUserlist();
  },
  methods: {
    // 獲取列表
    getUserlist() {
      this.tableLoading = true;
      this.$api
        .userList({
          page: this.page,
          pageSize: this.pageSize,
          realname: this.searchForm.realname,
          phone: this.searchForm.phone,
          admin_name: this.searchForm.admin_name,
          create_time_from:
            this.searchDate != [] &&
            this.searchDate != null &&
            this.searchDate != undefined
              ? this.searchDate[0]
              : "",
          create_time_to:
            this.searchDate != [] &&
            this.searchDate != null &&
            this.searchDate != undefined
              ? this.searchDate[1]
              : "",
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.code == 200) {
            this.userTableData = res.data.list;
            this.total = res.data.count;
          }
        });
    },
    // 分頁
    handleSizeChange(val) {
      this.pageSize = val;
      this.getUserlist();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getUserlist();
    },

    adminReset() {
      this.userForm = {
        admin_name: "",
        password: "",
        realname: "",
        phone: "",
        status: 1,
        role_ids: "",
        remark: "",
      };
      this.$nextTick(() => {
        this.$refs["userForm"].resetFields();
      });
    },
    // 獲取角色下拉
    getRoleSelect() {
      this.$api.getRole().then((res) => {
        this.userOptions = res.data.list;
      });
    },
    // 添加管理員角色
    addmenu() {
      this.userTitle = "添加";
      this.userFormRules = {
        realname: [
          { required: true, message: "請輸入真實姓名", trigger: "blur" },
        ],
        admin_name: [
          { required: true, message: "請輸入用戶名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "請輸入手機號", trigger: "blur" },
          { min: 11, max: 11, message: "請輸入正確的手機號", trigger: "blur" },
        ],
        password: [
          { required: true, message: "請輸入密碼", trigger: "blur" },
          { min: 6, max: 6, message: "請輸入6位數密碼", trigger: "blur" },
        ],
      };
      this.adminReset();
      this.userVisible = true;
      this.getRoleSelect();
    },
    // 編輯
    editUser(id) {
      this.getRoleSelect();
      this.adminReset();
      this.userTitle = "編輯";
      this.userVisible = true;
      this.$api.userDetail({ admin_id: id }).then((res) => {
        this.userForm = res.data;
        this.userForm.password = "";
        this.userFormRules = {
          realname: [
            { required: true, message: "請輸入真實姓名", trigger: "blur" },
          ],
          admin_name: [
            { required: true, message: "請輸入用戶名", trigger: "blur" },
          ],
          phone: [
            { required: true, message: "請輸入手機號", trigger: "blur" },
            {
              min: 11,
              max: 11,
              message: "請輸入正確的手機號",
              trigger: "blur",
            },
          ],
        };
      });
    },
    userSubmit() {
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          var loading = this.$loading("正在提交...");
          if (this.userForm.admin_id != "" && this.userForm.admin_id != null) {
            const params = {
              admin_id: this.userForm.admin_id,
              realname: this.userForm.realname,
              admin_name: this.userForm.admin_name,
              phone: this.userForm.phone,
              role_ids:
                this.userForm.role_ids != "" &&
                this.userForm.role_ids != [] &&
                this.userForm.role_ids != undefined
                  ? this.userForm.role_ids.join(",")
                  : "",
              password: this.userForm.password,
              remark: this.userForm.remark,
            };
            this.$api.editUser(params).then((res) => {
              loading.close();
              if (res.code == 200) {
                this.$message.success("添加成功");
                this.getUserlist();
                this.userVisible = false;
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.userForm.role_ids =
              this.userForm.role_ids != "" &&
              this.userForm.role_ids != [] &&
              this.userForm.role_ids != undefined
                ? this.userForm.role_ids.join(",")
                : "";
            this.$api
              .addUser(this.userForm)
              .then((res) => {
                loading.close();
                if (res.code == 200) {
                  this.$message.success("添加成功");
                  this.getUserlist();
                  this.userVisible = false;
                } else {
                  this.$message.error(res.msg);
                }
              })
              .catch((err) => {
                loading.close();
                console.log(err);
              });
          }
        }
      });
    },
    // 刪除
    deleteUser(id) {
      this.$confirm("此操作將永久刪除該用戶, 是否繼續?", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            type: "正在刪除",
            background: "rgba(255, 255, 255, 0.5)",
          });
          this.$api.delUser({ admin_id: id }).then((res) => {
            loading.close();
            if (res.code == 200) {
              this.$message.success("刪除成功");
              this.getUserlist();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch((err) => err);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/user/administratormenu.scss";
</style>