import { render, staticRenderFns } from "./administratormenu.vue?vue&type=template&id=617bef69&scoped=true&"
import script from "./administratormenu.vue?vue&type=script&lang=js&"
export * from "./administratormenu.vue?vue&type=script&lang=js&"
import style0 from "./administratormenu.vue?vue&type=style&index=0&id=617bef69&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617bef69",
  null
  
)

export default component.exports